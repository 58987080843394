.App {
  text-align: center;
  background: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.react-pdf__Document {
  display: inline-block;
  background: white;
}

.react-pdf__Page {
  background: white;
}

.react-pdf__Page__canvas {
  background: white;
}
